<script>
    import Layout from "@/router/layouts/main";
    import appConfig from "@/app.config";
    import PageHeader from "@/components/page-header";
    import axios from "axios";
    import Swal from "sweetalert2"
    import moment from 'moment';
    /**
     * Dashboard Component
     */
    export default {
        page: {
            title: "Perusahaan",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader
        },
        data() {
            return {
                title: "Perusahaan",
                items: [
                    {
                        text: "Master",
                        href: "/",
                    },
                    {
                        text: "Perusahaan",
                        active: true,
                    },
                ],
                // variable Page Table
                loadingTable: true,
                dataTable: [],
                pagingTable: [],
                showDataToTable: '',
                showDataFromTable: '',
                totalDataTable: '',
                currentTablePage: '',
                searchDataTable: '',
            };
        },
        mounted() {
            // Load data awal banget
            let self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perusahaan",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config).then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data[0];
                if(response_data.meta.code == 200){
                    self.loadingTable = false;
                    self.dataTable = response_data_fix.data;
                    self.pagingTable = response_data_fix.links;
					self.showDataToTable = response_data_fix.to;
					self.showDataFromTable = response_data_fix.from;
					self.totalDataTable = response_data_fix.total;
					self.currentTablePage = response_data_fix.current_page;
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response_data_fix.data.message
                    });
                }
            }).catch(function (error) {
                console.log(error);
            });
        },
        methods:{
            // Method dari masing masing fungsi di dalam file ini
            fullDateTimeFormat(datetime){
                return moment(datetime).format('YYYY-MM-DD HH:mm');
            },
            toPage(url) {
                let self = this;
				self.loadingTable = true;
                var config = {
                    method: "get",
                    url: url,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                };
				axios(config).then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if(response_data.meta.code == 200){
                        self.loadingTable = false;
                        self.dataTable = response_data_fix.data;
                        self.pagingTable = response_data_fix.links;
                        self.showDataToTable = response_data_fix.to;
                        self.showDataFromTable = response_data_fix.from;
                        self.totalDataTable = response_data_fix.total;
                        self.currentTablePage = response_data_fix.current_page;
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: response_data_fix.data.message
                        });
                    }
                }).catch(function (error) {
                    console.log(error);
                });
			},
            searchTable(){
                let self = this;
                self.loadingTable = true;
                var config = {
                    method: "get",
                    url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perusahaan",
                    params: {
                        search: self.searchDataTable
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                };
                axios(config).then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if(response_data.meta.code == 200){
                        self.loadingTable = false;
                        self.dataTable = response_data_fix.data;
                        self.pagingTable = response_data_fix.links;
                        self.showDataToTable = response_data_fix.to;
                        self.showDataFromTable = response_data_fix.from;
                        self.totalDataTable = response_data_fix.total;
                        self.currentTablePage = response_data_fix.current_page;
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: response_data_fix.data.message
                        });
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            }
        }
    };
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card-title">Semua Data Perusahaan</div>
                            </div>
                            <div class="col-md-6">
                                <div class="text-end">
                                    <!-- <router-link :to="{name: 'add-negara'}" class="btn btn-sm btn-success"><i class="fa fa-plus"></i> Tambah</router-link> -->
                                </div>
                            </div>
                            <div class="col-md-12">
                                <br/>
                                <div class="row">
                                    <div class="col-md-9">
                                        <!-- Untuk Filter Data -->
                                        &nbsp;
                                    </div>
                                    <div class="col-md-3"> 
                                        <input type="text" class="form-control" id="searchTable" v-on:change="searchTable" v-model="searchDataTable" placeholder="Cari Data ...">
                                    </div>
                                </div>
                                <br/>
                            </div>
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table mb-0 table-bordered table-condensed table-hover">
                                        <thead class="bg-dark text-center text-white">
                                            <tr>
                                                <th style="width: 50px;">No</th>
                                                <th>Nama Perusahaan</th>
                                                <th>NIB</th>
                                                <th>NPWP</th>
                                                <th>Penanggung Jawab</th>
                                                <th>Email</th>
                                                <th>No. Telepon</th>
                                                <th>Alamat</th>
                                                <th style="width: 150px;">Created At</th>
                                                <th style="width: 150px;">Updated At</th>
                                                <th style="width: 100px;">Status</th>
                                                <th style="width: 175px;">Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="loadingTable">
                                                <td colspan="12"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                            </tr>
                                            <tr v-for="(row_data, key_data) in dataTable" :key="key_data" v-else>
                                                <td class="text-center">
                                                    <div v-if="key_data+1 == 10">
                                                        {{currentTablePage}}0.
                                                    </div>
                                                    <div v-else>
                                                        {{(currentTablePage-1 != 0)?currentTablePage-1:''}}{{key_data+1}}.
                                                    </div>
                                                </td>
                                                <td>
                                                    {{ row_data.mpr_nama }}
                                                </td>
                                                <td>
                                                    {{ row_data.mpr_nib }}
                                                </td>
                                                <td>
                                                    {{ row_data.mpr_npwp }}
                                                </td>
                                                <td>
                                                    {{ row_data.mpr_penanggung_jawab }}
                                                </td>
                                                <td>
                                                    {{ row_data.mpr_email }}
                                                </td>
                                                <td>
                                                    {{ row_data.mpr_telp }}
                                                </td>
                                                <td>
                                                    {{ row_data.mpr_alamat }}
                                                </td>
                                                <td class="text-center">
                                                    {{ (row_data.created_at) ? fullDateTimeFormat(row_data.created_at) : "-" }}
                                                </td>
                                                <td class="text-center">
                                                    {{ (row_data.updated_at) ? fullDateTimeFormat(row_data.updated_at) : "-" }}
                                                </td>
                                                <td class="text-center">
                                                    <div v-if="row_data.status == 'ENABLE'">
                                                        <div class='badge badge-pill badge-soft-success font-size-12'>ENABLE</div>
                                                    </div>
                                                    <div v-else>
                                                        <div class='badge badge-pill badge-soft-danger font-size-12'>DISABLE</div>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    -
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="pt-4">
                                    Show {{ showDataFromTable }}-{{ showDataToTable }} From {{ totalDataTable }}
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div style="float: right !important;">
                                    <nav aria-label="pagination" class="mt-3">
                                        <ul class="pagination justify-content-start">
                                            <li v-for="(page, key_page) in pagingTable" v-bind:class="{ 'active': page.active }" :key="key_page" class="page-item">
                                                <div v-on:click="toPage(page.url)" class="page-link c-page-link-number"> 
                                                    <div v-html="page.label"></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>